<template>
  <div class="filter">
    <div class="filter__label tw-items-start">
      <div class="d-flex" style="flex-direction: column; align-items: flex-start">
        <span>Папки заказа</span>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-x-1.5 tw-gap-y-2 tw-font-medium">
      <div class="tw-text-[#9ba6b2] tw-text-xs tw-flex tw-items-center">Артикул объекта</div>
      <div class="tw-text-xs tw-text-[#3E3E3E]">
        <zem-dropdown-list
          v-model="article"
          :options="objectsList"
          class="tw-m-0"
          is-right
          search
          @on-search="getObjectsList"
          @on-delete="objectsList = []"
        />
      </div>
    </div>
    <div v-if="breadCrumbsList.length > 0" class="filter__bread-crumbs">
      <div>
        <span>{{ breadCrumbsList.slice(0, -1).join(' / ') }}</span>
        <span class="filter__bread-crumbs__last">
          {{ breadCrumbsList.length > 1 ? '/' : '' }} {{ breadCrumbsList[breadCrumbsList.length - 1] }}
        </span>
      </div>
    </div>
    <!--    <template v-if="!showCreateFirstDirectory">-->
    <FilesTree
      ref="treeContainer"
      :is-loading-tree="$store.state.other.isLoadingSidebar"
      :list="elements"
      is-hidden-search
    />
    <!--    </template>-->
  </div>
</template>

<script>
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ObjectsService from '@/services/objects.service'
import FilesTree from '@/components/Files/FilesTree.vue'

export default {
  components: {FilesTree, ZemDropdownList, ZemLink, ZemIcon, ZemCardTitle},
  data() {
    return {
      elements: [
        {
          id: 1,
          name: '12522',
          children: [
            {
              id: 3,
              name: 'Договор',
              children: [
                {
                  id: 2,
                  name: 'Исходники',
                },
                {
                  id: 4,
                  name: 'Подписано',
                },
              ],
            },
          ],
        },
      ],
      breadCrumbsList: ['12522', '12522-R1', 'Договор', 'Подписано'],
      showCreateFirstDirectory: false,
      article: null,
      order: null,
      ordersList: [],
      objectsList: [],
    }
  },
  methods: {
    getObjectsList(search) {
      this.isLoadingObjects = true
      ObjectsService.getObjectsList(search)
        .then(r => {
          this.objectsList = r.data
        })
        .finally(() => {
          this.isLoadingObjects = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  min-width: 336px !important;

  &__bread-crumbs {
    margin-top: 14px;
  }
}
</style>

<style lang="scss">
.sidebar-left__container--show {
  width: 100%;
}

.filter {
  display: block;
  padding: 20px 24px;
  overflow: auto;
  height: 100%;

  .checkbox-dnd {
    margin-bottom: 6px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
  }

  &__bread-crumbs {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    height: 14px;

    &__last {
      color: $color-accent;
    }
  }

  > .drag-area {
    margin-left: 0;
    height: auto;
    transition: all 0.4s ease;
    overflow: unset;

    > .item-group {
      display: block;

      .drag-area__name {
        font-size: 14px;
        line-height: 19px;
        min-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-products {
    padding: 20px;
    min-width: 336px;
  }
}
</style>
