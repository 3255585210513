<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show tw-min-w-[336px] tw-justify-start': $store.state.sidebars.leftSidebar}">
      <FileTree v-show="$store.state.sidebars.leftSidebar" ref="filter" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="$can('edit', 'directory-item-archive')"
      :is-archiving="$can('edit', 'directory-item-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'directory-item-delete')"
      :rows="reformatTableData"
      :sorting-fields="sortingFields"
      :title="$store.state.products.section.title"
      name="products"
      @show-right-sidebar="() => $store.commit('sidebars/changeRightSidebar', true)"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @archive-items="archiveItems"
      @update-data="updateData"
      @copy-item="copyItem"
      @return-from-archive="returnFromArchiveProducts"
    >
      <template slot="head-buttons-center">
        <template
          v-if="$store.state.products.selectedItemsForDelete.length === 0 && $can('edit', 'directory-item-export')"
        >
          <zem-link class="tw-gap-x-1.5">
            <download-icon class="mr-1" size="16" />
            <span>Переместить</span>
          </zem-link>
          <DownloadFiles />
        </template>
      </template>
    </TableContainer>
  </div>
</template>

<script>
import SidebarLeft from '@/components/SidebarLeft.vue'
import FileTree from '@/components/Files/FileTree.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import TableContainer from '@/components/TableContainer.vue'
import DownloadFiles from '@/components/Files/DownloadFiles.vue'

export default {
  components: {DownloadFiles, TableContainer, ZemLink, FileTree, SidebarLeft},
  data() {
    return {
      tableData: [
        {
          name: 'name',
          title: this.$t('Name'),
          sortable: true,
          width: 'tw-w-1/3',
        },
        {
          name: 'created_at',
          title: this.$t('Created at'),
          sortable: true,
          width: 'tw-w-1/4',
        },
        {
          name: 'updated_at',
          title: this.$t('Updated at'),
          sortable: true,
          width: 'tw-w-1/4',
        },
        {
          name: 'actions',
          title: this.$t('Actions'),
          width: 'tw-w-1/4',
        },
      ],
      sortingFields: ['name', 'created_at', 'updated_at'],
    }
  },
  mounted() {
    this.$store.commit('sidebars/changeLeftSidebar', true)
  },
}
</script>

<style lang="scss" scoped></style>
