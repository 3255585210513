<template>
  <div class="download-files" v-click-outside="vcoConfig">
    <zem-button @click="isShowPopup = true">
      <img :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" />
      Загрузить файл
    </zem-button>
    <div v-if="isShowPopup" class="download-modal">
      <div class="download-modal__wrapper">
        <h3 class="tw-text-[#3E3E3E] tw-text-xs tw-font-bold">Загрузить файл</h3>
        <label for="" class="tw-text-[10px] tw-text-[#9BA6B2] tw-mt-2.5">Укажите путь к файлу...</label>
        <zem-input class="tw-text-sm" type="file" @input="file = $event.target.files[0]" />
        <div class="tw-flex tw-gap-x-3 tw-justify-end tw-mt-2.5">
          <zem-link @click="isShowPopup = false">Отмена</zem-link>
          <zem-button @click="isShowPopup = false">Загрузить</zem-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemInput from '@/components/ui/ZemInput.vue'

export default {
  components: {ZemInput, ZemLink, ZemButton},
  data() {
    return {
      file: null,
      isShowPopup: false,
      vcoConfig: {
        handler: this.onChangeStatus,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
    }
  },
  methods: {
    onChangeStatus() {
      this.isShowPopup = false
    },
  },
}
</script>

<style lang="scss" scoped>
.download-files {
  position: relative;

  .download-modal {
    position: absolute;
    top: 32px;
    right: 0;
    width: 200px;
    background-color: #fff;
    z-index: 50;
    overflow: hidden;
    border-radius: 4px;
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);

    &__wrapper {
      padding: 16px 10px;
      background-color: #fff;
    }
  }
}
</style>
